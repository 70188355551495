export default defineI18nConfig(() => ({
	legacy: false,
	locale: 'de',
	messages: {
		// Deutsch
		de: {
			language: 'Deutsch',
			agree: 'Einverstanden',
			error: 'Fehler',
			errorText: 'Es ist ein Fehler aufgetreten.',
			backToHome: 'Zurück zur Startseite',
			contentDoesNotExist: 'Dieser Inhalt existiert nicht (mehr). Möglicherweise wurde er verschoben oder gelöscht.',
			dataProtection: 'Datenschutz'
		},
		// Français
		fr: {
			language: 'Français',
			agree: 'D\'accord',
			error: 'Erreur',
			errorText: 'Une erreur s\'est produite.',
			backToHome: 'Retour à l\'accueil',
			contentDoesNotExist: 'Ce contenu n\'existe pas (plus). Il a peut-être été déplacé ou supprimé.',
			dataProtection: 'Protection des données'
		}
	}
}))
